import React from 'react';
// import purplelady from '../images/jolia.png';

// const photos = [purplelady];

const PhotoGrid = ({ photos }) => {
  return (
    <div className="photo-grid">
      {photos.map((photo, index) => (
        <img key={index} src={photo} alt={`Photo ${index}`} className="photo-item" />
      ))}
    </div>
  );
};

export default PhotoGrid;
