import React from 'react'
import MemoPad from '../components/MemoPadBackground'
// import memopad01 from "../images/memopad01.jpg";
// import Me from '../components/JoliaPhoto'
import Jolia from "../images/jolia.png";
import SkillsIcons from '../components/SkillsIcons';
import pinkreact from '../images/pinkreact.png';
import pinkpython from '../images/pinkpython.png';
import pinkjavascript from '../images/pinkjavascript.png';
import pinkfigma from '../images/pinkfigma.png';


function About () {
    return (
    <div className='row'>
        <div className='memopad'>
        <p className='memopadtext'>Hello! My name Jolia Tsan and I’m a sixteen year old highschool junior currently attending Boston Latin school. I work at Artists for Humanity as a web and game developer. I previously animated for Artists for Humanity using Adobe suite. My work at AFH ranges from creating interactive displays for events to doing client work whether it be animation or web applications. I aspire to do creative work in the future that will allow me to immerse myself with technology.</p>
        {/* <MemoPad/> */}
        </div>

        <div>

        <div className='skillcontainer'>
           <SkillsIcons
                    icon={pinkreact}
                    text="React"
            />
            <SkillsIcons
                    icon={pinkjavascript}
                    text="Javascript"
            />
        </div>
        <div className='skillcontainer'>
            <SkillsIcons
                    icon={pinkpython}
                    text="Python"
            />

            <SkillsIcons
                    icon={pinkfigma}
                    text="Figma"
            /> 
        </div>
        <img
                src={Jolia}
                height={450}
                // width={175} 
                alt="photo of me"
            /> 
        
        </div>

                
        </div>    
    
        
    )
}

export default About