import React from 'react'

function Contact () {
    return (
    <div> 
        <h1>Hiii</h1>
    </div>
    )
}

export default Contact