import React from 'react'
import Navbar from '../components/NavBar'

function Music () {
    return (
    <div> 
        <Navbar/>
    </div>
    )
}

export default Music