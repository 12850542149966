import React from "react";
// import Box from "../images/pinkrectangle.png";


function SkillsIcons(props) {
    return (
        <div className="skills-icons-background-container">
        <div className="skills-icons-content">
          <img className="icon" src={props.icon} alt="Icon" />
          <p className="SItextScholarlyAmbitionShadow">{props.text}</p>
        </div>
      </div> 
    );
            };  
export default SkillsIcons;
