import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Welcome from './pages/Welcome';
import Home from './pages/Home';
import About from './pages/AboutMe';
import Contact from './pages/Contact';
import MyWork from './pages/MyWork';
import Art from './pages/Art';
import Games from './pages/Games';
import Projects from './pages/Projects';
import Clothing from './pages/Clothing';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Welcome/>,
  },
  {
    path: "home",
    element: <Home/>,
  },
  {
    path: "aboutme",
    element: <About/>,
  },
  {
    path: "contact",
    element: <Contact/>,
  },
  {
    path: "mywork",
    element: <MyWork/>,
  },
  {
    path: "art",
    element: <Art/>,
  },
  {
    path: "games",
    element: <Games/>,
  },
  {
    path: "projects",
    element: <Projects/>,
  },
  {
    path: "clothing",
    element: <Clothing/>,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);