import { NavLink } from 'react-router-dom';
import Star01 from './Star';
import { Link } from 'react-router-dom';


const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="container">
        <Link to = '/'>
        <div className="logo">
          <Star01 />
        </div>
        </Link>
        <div className="nav-elements">
          <ul>
            <li className="hover-underline-animation">
              <NavLink to="/">Home</NavLink>
            </li>
            <li className="hover-underline-animation">
              <NavLink to="/mywork">My Work</NavLink>
            </li>
            <li className="hover-underline-animation">
              <NavLink to="/aboutme">About Me</NavLink>
            </li>
            <li className="hover-underline-animation">
              <NavLink to="/contact">Contact</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
